import { UserOutlined } from '@ant-design/icons';
import { Avatar as AvatarAntd } from 'antd';
import { useLayoutActionsContext } from '../@crema/utility/AppContextProvider/LayoutContextProvider';
import { useAuthUser } from '../@crema/utility/AuthHooks';
import './Avatar.style.less';

const Avatar = ({ hasBadge, ...props }) => {
  const { user } = useAuthUser();
  const { setModalUserId } = useLayoutActionsContext();

  const badgeStyle = {
    BASIC: 'basic',
    SILVER: 'silver',
    GOLD: 'gold',
  };

  const badge = hasBadge ? badgeStyle[props.user?.badge] : '';

  return (
    <AvatarAntd
      src={props.src || props.user?.avatar}
      icon={<UserOutlined />}
      {...props}
      className={`${props.className || ''} btn-pointer badge-${badge || ''}`}
      alt={props.alt || props.user?.name}
      title={props.title || props.user?.name}
      onClick={() => {
        props.onClick && props.onClick();
        if (user?.id !== props.user?.id) {
          setModalUserId(props.user?.id);
        }
      }}>
      {props.user?.name?.[0]}
    </AvatarAntd>
  );
};

export default Avatar;
