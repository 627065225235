const routesConfig = [
  {
    id: 'home',
    title: 'HOME',
    messageId: 'sidebar.search',
    path: '/home',
    type: 'item',
    icon: 'icon-ic_home',
  },
  {
    id: 'voice',
    title: 'VOICES',
    messageId: 'sidebar.connection',
    path: '/voice',
    type: 'item',
    icon: 'icon-ic_message',
  },
  {
    id: 'network',
    title: 'NETWORK',
    messageId: 'sidebar.connection',
    path: '/network',
    type: 'item',
    icon: 'icon-ic_network',
  },
  {
    id: 'community',
    title: 'COMMUNITY',
    messageId: 'sidebar.community',
    path: '/community',
    type: 'item',
    icon: 'ico2-icc_community',
  },
  {
    id: 'resource',
    title: 'RESOURCES',
    messageId: 'sidebar.resource',
    path: '/resource',
    type: 'item',
    icon: 'ico2-ic_resources',
  },
  {
    id: 'chat',
    title: 'CHAT',
    messageId: 'sidebar.chat',
    path: '/chat',
    type: 'item',
    icon: 'icon-ic_chat',
  },
];
export default routesConfig;
