import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const ResourcePage = React.lazy(() => import('./ResourcePage'));
const ResourceDetailPage = React.lazy(() => import('./ResourceDetailPage'));

export const resourceRootPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/resource',
    element: <ResourcePage />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/resource/:id',
    element: <ResourceDetailPage />,
  },
];
