import { Button, Image, Popover, Space, Spin, message } from 'antd';
import Text from 'antd/lib/typography/Text';
import copy from 'copy-text-to-clipboard';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from 'react-share';
import ShareOnSocial from 'react-share-on-social';
import './voice.less';

const ShareModal = ({
  children,
  buttonChildren,
  title: shareTitle,
  description,
  url,
  className,
  buttonType = 'link',
  icon,
  danger,
  style,
  onClick,
  loading = false,
  trigger = 'hover',
}) => {
  const shareUrl = url || 'https://mentoringsg.com';
  const title = shareTitle || 'Mentoring SG Connect';

  const onCopy = () => {
    copy(shareUrl);
    message.success('Copied!');
    console.log('Copied!', title, description);
  };

  return (
    <Space>
      <Popover
        padding={8}
        color='white'
        overlay={false}
        zIndex={2000}
        trigger={trigger}
        placement='bottom'
        content={
          <Spin spinning={loading}>
            <div>
              <Text className='fw-600'>SHARE THIS</Text>
              <br />
              <Space className='mt-8'>
                <Button type='link' onClick={onCopy} style={{ padding: 0 }}>
                  <Image
                    preview={false}
                    src='/assets/images/share/ic_link.svg'
                  />
                </Button>
                <FacebookShareButton url={shareUrl} title={title}>
                  <Image preview={false} src='/assets/images/share/ic_fb.svg' />
                </FacebookShareButton>
                <LinkedinShareButton url={shareUrl} title={title}>
                  <Image
                    preview={false}
                    src='/assets/images/share/ic_linkedin.svg'
                  />
                </LinkedinShareButton>
                <WhatsappShareButton url={shareUrl} title={title}>
                  <Image
                    preview={false}
                    src='/assets/images/share/ic_whatsup.svg'
                  />
                </WhatsappShareButton>
                <TelegramShareButton url={shareUrl} title={title}>
                  <Image
                    preview={false}
                    src='/assets/images/share/ic_tele.svg'
                  />
                </TelegramShareButton>
                <ShareOnSocial
                  linkFavicon={'/logo192.png'}
                  textToShare={title}
                  link={shareUrl}
                  linkTitle={title}
                  noReferer>
                  <Button
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '48px',
                      backgroundColor: '#efefef',
                      marginBottom: '8px',
                      padding: 0,
                    }}>
                    <Image
                      preview={false}
                      width={24}
                      height={24}
                      src='/assets/images/share/more_icon.svg'
                    />
                  </Button>
                </ShareOnSocial>
              </Space>
            </div>
          </Spin>
        }>
        {buttonChildren ? (
          buttonChildren
        ) : (
          <Button
            style={style}
            danger={danger}
            onClick={onClick}
            icon={icon}
            type={buttonType}
            className={`${className} btn-share`}>
            {children}
          </Button>
        )}
      </Popover>
    </Space>
  );
};

export default ShareModal;
